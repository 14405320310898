import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_word_cloud = _resolveComponent("tm-word-cloud")!
  const _component_report_graph = _resolveComponent("report-graph")!

  return (_openBlock(), _createBlock(_component_report_graph, {
    title: "Popular responses word cloud",
    icon: "tmi-word-cloud",
    "empty-text": "The most common words in responses will be displayed here.",
    size: "large",
    class: "campaigns-sms-details-word-cloud"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_word_cloud, { words: _ctx.campaignsWordCloud }, null, 8, ["words"])
    ]),
    _: 1
  }))
}

import { defineComponent } from 'vue'
import CampaignsSmsDetailsDelivery from '@/components/pages/campaigns/details/sms/overview/CampaignsSmsDetailsDelivery.vue'
import CampaignsSmsDetailsAnalysis from '@/components/pages/campaigns/details/sms/overview/CampaignsSmsDetailsAnalysis.vue'
import CampaignsSmsDetailsAnalytics from '@/components/pages/campaigns/details/sms/overview/CampaignsSmsDetailsAnalytics.vue'

export default defineComponent({
  components: {
    CampaignsSmsDetailsAnalytics,
    CampaignsSmsDetailsAnalysis,
    CampaignsSmsDetailsDelivery,
  },
})

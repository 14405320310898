
import { defineComponent } from 'vue'
import { campaignSmsDetailsStats } from '@/definitions/campaigns/data'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'

export default defineComponent({
  components: {
    ReportCards,
  },
  setup() {
    return {
      campaignSmsDetailsStats,
    }
  },
})


import { defineComponent, ref } from 'vue'
import ReportEmpty from '@/components/shared/templates/reports/ReportEmpty.vue'
import CampaignsDetailsBlock from '@/components/pages/campaigns/details/CampaignsDetailsBlock.vue'
import CampaignsSmsDetailsStats from '@/components/pages/campaigns/details/sms/overview/CampaignsSmsDetailsStats.vue'
import CampaignsDetailsTimeline from '@/components/pages/campaigns/details/CampaignsDetailsTimeline.vue'
import {
  getCampaignsSmsGeographyChartData, getSmsCampaignsTimelineChartData
} from '@/definitions/shared/charts/campaigns/data'
import CampaignsDetailsGeography from '@/components/pages/campaigns/details/CampaignsDetailsGeography.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    ReportEmpty,
    CampaignsDetailsGeography,
    CampaignsDetailsTimeline,
    CampaignsSmsDetailsStats,
    CampaignsDetailsBlock,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const currentGraph = ref('day')

    return {
      isEmptyMode,
      currentGraph,
      getSmsCampaignsTimelineChartData,
      getCampaignsSmsGeographyChartData,
    }
  },
})

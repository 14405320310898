import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaigns_sms_details_delivery = _resolveComponent("campaigns-sms-details-delivery")!
  const _component_campaigns_sms_details_analytics = _resolveComponent("campaigns-sms-details-analytics")!
  const _component_campaigns_sms_details_analysis = _resolveComponent("campaigns-sms-details-analysis")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_campaigns_sms_details_delivery),
    _createVNode(_component_campaigns_sms_details_analytics),
    _createVNode(_component_campaigns_sms_details_analysis)
  ]))
}

import { defineComponent } from 'vue'
import CampaignsDetailsBlock from '@/components/pages/campaigns/details/CampaignsDetailsBlock.vue'
import CampaignsDetailsRateBlock from '@/components/pages/campaigns/details/CampaignsDetailsRateBlock.vue'
import { campaignsSmsAnalyticsRates } from '@/definitions/campaigns/data'
import CampaignsSmsDetailsWordCloud from '@/components/pages/campaigns/details/sms/overview/CampaignsSmsDetailsWordCloud.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    CampaignsSmsDetailsWordCloud,
    CampaignsDetailsRateBlock,
    CampaignsDetailsBlock,
  },
  setup() {
    const { isEmptyMode } = useModes()

    return {
      campaignsSmsAnalyticsRates,
      isEmptyMode,
    }
  },
})


import { defineComponent, computed } from 'vue'
// eslint-disable-next-line
// @ts-ignore
import VueWordCloud from 'vuewordcloud'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { styleVars, rgbToHex } from '@/compositions/styleVariables'
import { randomIntFromInterval } from '@/services/utils'

export default defineComponent({
  components: {
    TmTooltip,
    VueWordCloud,
  },
  props: {
    words: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      default: '220px',
    },
  },
  setup(props) {
    const getColor = () => {
      const colorsArr = [rgbToHex(styleVars.blue400), rgbToHex(styleVars.blue500), rgbToHex(styleVars.blue600), rgbToHex(styleVars.blue650), rgbToHex(styleVars.blue700)]

      return colorsArr[randomIntFromInterval(0, colorsArr.length - 1)]
    }

    const totalWeight = computed(() => props.words.reduce((currentSum: any, currentItem: any): number => {
      if (typeof currentSum !== 'number') {
        return currentSum[1]
      }

      return +currentItem[1] + currentSum
    }))

    const getPercentOfTotal = (weight: number) => {
      if (typeof totalWeight.value !== 'number') { return }

      return (weight / totalWeight.value * 100).toFixed(2)
    }

    return {
      getPercentOfTotal,
      totalWeight,
      getColor,
    }
  },
})


import { defineComponent } from 'vue'
import TmWordCloud from '@/components/shared/TmWordCloud.vue'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import { campaignsWordCloud } from '@/definitions/campaigns/data'

export default defineComponent({
  components: {
    TmWordCloud,
    ReportGraph,
  },
  setup() {
    return {
      campaignsWordCloud,
    }
  },
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ff0cd83"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaigns-sms-details-analysis__grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaigns_details_timeline = _resolveComponent("campaigns-details-timeline")!
  const _component_campaigns_details_geography = _resolveComponent("campaigns-details-geography")!
  const _component_report_empty = _resolveComponent("report-empty")!
  const _component_campaigns_sms_details_stats = _resolveComponent("campaigns-sms-details-stats")!
  const _component_campaigns_details_block = _resolveComponent("campaigns-details-block")!

  return (_openBlock(), _createBlock(_component_campaigns_details_block, {
    text: "An overview of the campaign’s peak activity times, geography and costs.",
    title: "Analysis"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_campaigns_details_timeline, {
        "get-timeline-data": _ctx.getSmsCampaignsTimelineChartData,
        class: "mb-6"
      }, null, 8, ["get-timeline-data"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_campaigns_details_geography, { "get-data": _ctx.getCampaignsSmsGeographyChartData }, null, 8, ["get-data"]),
        (_ctx.isEmptyMode)
          ? (_openBlock(), _createBlock(_component_report_empty, {
              key: 0,
              icon: "tmi-paid-outline",
              title: "Campaign summary",
              text: "Cost details will appear here after the campaign is sent.",
              size: "medium"
            }))
          : (_openBlock(), _createBlock(_component_campaigns_sms_details_stats, { key: 1 }))
      ])
    ]),
    _: 1
  }))
}